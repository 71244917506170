import {
  Scooter,
  ScooterInfo,
  VariantScooter,
  ScooterLocation,
  ScooterBatteryUsages,
  BatteryUsage,
  DataTripHistory,
  DataStationsVisited
} from 'interfaces/scooter';
import { Pagination } from 'interfaces/common';

import {
  GET_SCOOTERS,
  GET_SCOOTER_DETAIL,
  GET_VARIANT_SCOOTERS,
  GET_SCOOTER_LOCATION,
  GET_SCOOTER_BATTERY_USAGES,
  GET_TRIP_HISTORY_SCOOTER,
  GET_VISITED_STATION,
  LOGOUT,
  ScooterActionTypes,
} from './actionTypes';

interface ScooterState {
  scooters: {
    data: Scooter[];
    pagination: Pagination;
  };
  scooterDetail: ScooterInfo;
  variantScooters: {
    data: VariantScooter[];
    pagination: Pagination;
  };
  scooterLocation: ScooterLocation[];
  scooterBatteryUsages: ScooterBatteryUsages;
  batteryUsage: BatteryUsage;
  tripHistory: {
    data: DataTripHistory[];
    pagination: Pagination;
  };
  stationVisited: {
    data: DataStationsVisited[];
    pagination: Pagination;
  };
}

const initPagination: Pagination = {
  count: 0,
  limit: 0,
  offset: 0,
  order: '',
  page: 0,
  page_total: 0,
  search: '',
  sort: 'asc'
};

const initState: ScooterState = {
  scooters: {
    data: [],
    pagination: initPagination
  },
  scooterDetail: {
    vin: 0,
    type: '',
    plat_number: '',
    status: '',
    showroom_id: 0,
    parent: null,
    childs: null,
    location: '',
    odo: 0
  },
  variantScooters: {
    data: [],
    pagination: initPagination
  },
  scooterLocation: [],
  batteryUsage: {
    average: 0,
    energy_usage_per_day: []
  },
  scooterBatteryUsages: {
    soc: '',
    battery_condition: '',
    remaining_range: '',
    most_recent_efficiency: '',
    temperature: '',
    battery_failure: '',
    usages: {
      average: 0,
      energy_usage_per_day: []
    }
  },
  tripHistory: {
    data: [],
    pagination: initPagination
  },
  stationVisited: {
    data: [],
    pagination: initPagination
  },
};

const scooterReducer = (state = initState, action: ScooterActionTypes): ScooterState => {
  switch (action.type) {
    case GET_SCOOTERS:
      return {
        ...state,
        scooters: action.data
      };

    case GET_SCOOTER_DETAIL:
      return {
        ...state,
        scooterDetail: action.data
      };

    case GET_VARIANT_SCOOTERS:
      return {
        ...state,
        variantScooters: action.data
      };

    case GET_SCOOTER_LOCATION:
      return {
        ...state,
        scooterLocation: action.data
      };

    case GET_SCOOTER_BATTERY_USAGES:
      return {
        ...state,
        batteryUsage: action.data
      };

    case GET_TRIP_HISTORY_SCOOTER:
      return {
        ...state,
        tripHistory: action.data
      };

    case GET_VISITED_STATION:
      return {
        ...state,
        stationVisited: action.data
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};

export default scooterReducer;
