import React, { useEffect } from 'react';

import {
  Box,
  Text,
  BarChart,
  Select,
  EmptyState
} from 'components';

import { Images } from 'consts';
import { hooks } from 'helpers';

import { selectors } from 'store/selectors';
import * as actions from 'store/actions';

import {
  getWeeks,
  group_by_month,
  convertFilterToReportDate
} from './helpers';

type BoxBatteryProps = {
  widthBox: number;
  filterMenu: {
    activeFilter: any;
    visibleFilter: any;
    onVisibleChange: (visible: boolean, key: string) => void;
    onClickFilterMenu: (params?: any) => void;
  };
  routeId?: string;
};

const BoxBattery: React.FC<BoxBatteryProps> = ({
  widthBox,
  filterMenu,
  routeId
}) => {
  const dispatch = hooks.useAppDispatch();

  const getBatteryPackBatteryUsages = dispatch(actions.getBatteryPackBatteryUsages);
  const batteryPackBatteryUsage = hooks.useAppSelector(selectors.battery.batteryPackBatteryUsage);

  const dataChart = batteryPackBatteryUsage || [];

  useEffect(() => {
    if (routeId) {
      const report_date = convertFilterToReportDate(filterMenu.activeFilter.batteryConsumption);

      getBatteryPackBatteryUsages(routeId || '', { report_date });
    }
  }, [filterMenu.activeFilter.batteryConsumption]);

  const renderFilterMenu = (key: string) => {

    return (
      <Select
        keySelect={ key }
        visible={ filterMenu.visibleFilter[key] }
        onVisibleChange={ filterMenu.onVisibleChange }
        selectTitle={ filterMenu.activeFilter[key] }
        onClickSelectItem={ filterMenu.onClickFilterMenu }
        selectOptions={ [
          'Daily',
          'Weekly',
          'Monthly'
        ] }
      />
    );
  };

  const renderEmptyData = () => {
    return (
      <EmptyState
        background={ { height: 240 } }
        image={ {
          src: Images.emptyState.chart,
          width: 56,
          height: 56
        } }
        text={ 'No Stats Available for ' + filterMenu.activeFilter.batteryConsumption }
      />
    );
  };

  const convertDataChart = () => {

    if (filterMenu.activeFilter.batteryConsumption === 'Monthly') {
      return group_by_month(dataChart)?.slice(0, 5)
        ?.reverse();
    }
    if (filterMenu.activeFilter.batteryConsumption === 'Weekly') {
      return getWeeks(dataChart, 'battery_usage')?.slice(0, 5);
    }

    if (filterMenu.activeFilter.batteryConsumption === 'Daily') {
      return dataChart?.map(x => (
        {
          battery_usage: x?.battery_usage,
          created_date: String(x?.created_date)?.slice(0, 10),
        }
      )).slice(0, 7);
    }
  };

  const dataChartConverted = convertDataChart();
  const dataKeyXAxis = filterMenu.activeFilter.batteryConsumption === 'Daily' ? 'created_date' : 'date_x';

  return (
    <Box
      mt={ 20 }
      padding={ 20 }
      width={ widthBox }
    >
      <div className='justify-align-center mb4'>
        <Text size='m' weight={ 700 }>Battery Consumption</Text>
        { renderFilterMenu('batteryConsumption') }
      </div>
      {
        dataChart && Object.values(dataChart)?.length ? (
          <div className='col center-content'>
            <BarChart
              data={ dataChartConverted }
              dataKey={ {
                xAxis: dataKeyXAxis,
                bar: 'battery_usage'
              } }
              unit=' WH'
              margin={ { left: 0 } }
              width={ widthBox - 40 }
              height={ 249 }
              heightAxis={ 55 }
              sizeFont={ 10 }
            />
          </div>
        ) : renderEmptyData()
      }
    </Box>
  );
};

export default BoxBattery;
